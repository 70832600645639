import { Injectable } from '@angular/core';
/**
 * ### Loader Service
 * El servicio de Loader es usado por el interceptor API-URL.
 * El servicio consta del método show para mostrar el loader e incrementar el contador, y el método hide, que decrementa el contador
 * y al llegar a 0 esconde por completo el loader. Esto funciona de manera que, cuando se inicia una petición, se suma al contador, y 
 * cuando se finaliza dicha petición, se resta al contador.Cuando todas las peticiones hayan finalizado, el loader se esconde automáticamente.
 */
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public  showLoader: Boolean = false;
  private counter:    number  = 0;
  
  /**
   * Instancia el loader y suma 1 al contador.
   */
  public show(): void {
    this.counter += 1;
    this.showLoader = true;
  }

  /**
   * Resta 1 al contador y si el contador es igual a 0, esconde el loader.
   */
  public hide(): void {
    this.counter -= 1;
    this.counter === 0 ? 
    this.showLoader = false : false; 
  }

}
